export const APP_TITLE = "TruckerGIG-TRMADMIN";
export const APP_URL_BASE = "https://trmadminqa.truckergig.com/";
export const  API_URL_BASE = "https://crmqaapi.truckergig.com/";
export const APPCOMMON_URL_BASE = "https://adminqa.truckergig.com/";
export const userMenuGroups = ["default", "profile"];
export const tableDataServer = {
    filter: true,
    pagination: true,
    sort: true,
    cellEdit: false
};
export const tableNames = {
    carriers: "carriers",
    vendors: "vendors",
    jobs: "jobs",
    jobApplications: "job_applications",
    candidates: "candidates",
    services: "services",
    assets: "assets",
    clickouts: "service_click_outs",
    contactus: "contact_us_requests",
    contacts: "contacts",
    getAquote: "get_a_quote",
    greetings: "greetings",
    eldproviders: "eldproviders",
    lane: "lanes",
    promotions: "promotions",
    licenseType: "license_type",
    brokers: "brokers",
    loads: "loads",
    emailtemplatetype: "emailtemplatetype",
    emailaccountsetting: "emailaccountsetting"
};

export const noteTypes = {
    carriers: 2,
    vendors: 3,
    brokers: 17,
    assets: 16,
    other: 1,
    task: 4,
    user: 5,
    carrierDataFile: 6,
    vendorDataFile: 7,
    jobs: 8,
    jobApplications: 9,
    candidates: 10,
    candidatesProfile: 11,
    services: 12,
    candidateDataFile: 13,
    shipper: 18,
    loads: 19
}
export const taskStatus = {
    ASSIGNED: 1,
    ACKNOWLEDGED: 2,
    COMPLETED: 3,
    DELETED: 4
}

export const clientCategories = {
    CARRIER: 2,
    BROKER: 3,
    SHIPPER: 4,
}

export const jobStatus = {
    OPEN: 1,
    ONHOLD: 2,
    HIRED: 3
}

export const applicationStatus = {
    OPEN: 1,
    SCREENING: 2,
    REJECTED: 3,
    HIRED: 4
}

export const profileStatus = {
    INPROGRESS: 1,
    SUBMITTED: 2
}

export const taskType = {
    CARRIER_DUPLICATE: 'Carrier Duplicates',
    VENDOR_DUPLICATE: 'Vendor Duplicates'
}

export const categoryStatus = {
    NO: 0,
    YES: 1
}

export const emailAccountStatus = {
    NO: 0,
    YES: 1
}

export const greetingStatus = {
    NO: 0,
    YES: 1
}
export const promotionsStatus = {
    NO: 0,
    YES: 1
}

export const eldproviderStatus = {
    NO: 0,
    YES: 1
}

export const laneStatus = {
    NO: 0,
    YES: 1
}

export const licenseTypeStatus = {
    NO: 0,
    YES: 1
}

export const countryStatus = {
    NO: 0,
    YES: 1
}

export const loadStatus = {
    REQUESTED: 10,
    ASSIGNED: 20,
    ACCEPTED: 30,
    DISPATCHED: 40,
    PICKEDUP: 50,
    ENROUTE: 60,
    DELIVERED: 70,
    CANCELLED: 80,
}


export const dateTimeFormat = "MMM-DD-YYYY hh:mm A";
export const dateFormat = "MMM-DD-YYYY";
export const dateTimeDBFormat = "YYYY-MM-DDTHH:mm:ss.SSS";
export const dateDBFormat = "YYYY-MM-DD";
